import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo from '../../Assets/digiesim.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { toast } from "react-toastify";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const Navbar = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const FirstName = localStorage.getItem("FirstName")
    const LastName = localStorage.getItem("LastName")
    const UserUuid = localStorage.getItem("UserUuid")

    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for icon

    const handleIconClick = () => {
        setShowSideNav(!ShowSideNav);
        setIsMenuOpen(!isMenuOpen);
    };

    const [ShowSideNav, setShowSideNav] = useState(false);

    const [scroll, setScroll] = useState(false);   // This is for making the navbar sticky.

    useEffect(() => {
        const handleScroll = () => {
            if ((document.documentElement.scrollTop > 30 || window.scrollY > 30)) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    const handleScrolls = (event) => {
        event.preventDefault();
        const targetId = event.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
        });
        setShowSideNav(false);
        setIsMenuOpen(false);
    };

    const HandleLogin = () => {
        navigate('/login');
    }

    const HandleLogout = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/logout`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    navigate('/login');
                    // window.location.reload()
                    localStorage.clear()
                    toast.success(result.Msg, {
                        position: "top-right",
                        autoClose: 2000,
                        toastId: customId
                    });
                } else if (result.Status == "0") {
                    if (result.Msg === "Account Doesn't Exists") {
                        localStorage.clear()
                        // window.location.reload();
                        navigate("/login")
                    }
                    toast.success("Sucessfully Logged Out", {
                        position: "top-right",
                        autoClose: 2000,
                        toastId: customId
                    });
                    return;
                }
            })
    }


    const handleAccountInfo = () => {
        navigate("/account_information")
    }

    return (
        <>
            <div className={`${scroll ? "navbar stickyNavbar" : "navbar"} `}>
                <Link to='/' > <img src={Logo} id="navbarLogo" /></Link>

                <div id="navbarMenu">
                    <a href='/' className='nav-abc'>Home</a>
                    <p className='nav-abc'>|</p>
                    <a href='/all_countries&regions' className='nav-abc'>Destination</a>
                    <p className='nav-abc'>|</p>
                    <a href="https://help.digiesim.com/hc/en-us" className='nav-abc'> FAQs</a>

                    {
                        !UserUuid && <>
                            <p className='nav-abc'>|</p>
                            <a onClick={HandleLogin} id='LoginPageButton' className='nav-login-btn' >Login / Sign Up</a>
                        </>
                    }

                    {
                        UserUuid && <>
                            <p className='nav-abc'>|</p>
                            <a className='UserOptions'> {FirstName} {LastName}  <KeyboardArrowDownIcon style={{ fontWeight: '10px', marginLeft: '5px' }} />
                                <div id='UserOptionsMenu' >
                                    <ul>
                                        <li onClick={handleAccountInfo}>My digiesim</li>
                                        {/* <li>Orders</li> */}
                                        {/* <li>My SIMs</li> */}
                                        <li onClick={HandleLogout} >Logout <LogoutIcon style={{
                                            fontSize: '18px',
                                            color: 'red'
                                        }} /> </li>
                                    </ul>
                                </div>
                            </a>
                        </>
                    }
                </div>

                {/* */}
                {isMenuOpen ? (
                    <CloseIcon id="SideNavButton" onClick={handleIconClick} style={UserUuid ? { height: '40px', width: '40px' } : {}} />
                ) : (
                    UserUuid ? (
                        <a id="SideNavButton" onClick={handleIconClick}>
                            {FirstName.charAt(0)}{LastName.charAt(0)}
                        </a>
                    ) : (
                        <MenuIcon id="SideNavButton" onClick={handleIconClick} />
                    )
                )}

            </div>

            <div className="sidenav" style={{ width: ShowSideNav ? '100%' : '0%', overflow: 'hidden' }} >
                {UserUuid && <a
                    style={{
                        border: '2px #292B2E solid',
                        width: 'fit-content',
                        padding: '10px 15px',
                        borderRadius: '25px',
                        marginLeft: '10px',
                        fontWeight: '500',
                        backgroundColor: '#00A1E4',
                        color: 'white'
                    }}
                > {FirstName} {LastName} </a>}

                <a href='/'>Home <TrendingFlatIcon style={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '18px' }} /></a>

                {UserUuid && <a href="" onClick={handleAccountInfo}>My Digiesim <TrendingFlatIcon style={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '18px' }} /></a>}


                <a href="/all_countries&regions" >Destinations <TrendingFlatIcon style={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '18px' }} /></a>

                <a href="https://help.digiesim.com/hc/en-us" >FAQs <TrendingFlatIcon style={{ color: '#FFFFFF', marginLeft: '10px', fontSize: '18px' }} /></a>

                {UserUuid ? (
                    <a href="#" onClick={HandleLogout}>Logout <LogoutIcon style={{ color: '#FFFFFF', margin: '3px 0 0 10px', fontSize: '18px' }} /> </a>
                ) : (
                    <a onClick={HandleLogin} id="LoginPageButton">Login / Sign Up</a>
                )
                }
            </div>
        </>
    )
}

export default Navbar
