import React, { useState } from 'react';
import { Button } from '@mui/material';
import Flag from 'react-world-flags';
import { useNavigate } from 'react-router-dom';
import './FamousCountries.css';
import { ArrowRightRounded } from '@mui/icons-material';
import countryData from 'country-data'; // import the country-data package

const FamousCountries = () => {
    const navigate = useNavigate();

    const CountryList = [
        "GBR", "TUR", "USA", "FRA", "ESP", "ITA", "CHN", "JPN", "THA", "SAU", "PRT", "ARE"
    ];

    const FullCountryList = [
        "AFG", "ALB", "DZA", "AND", "AGO", "ARG", "ARM", "AUS", "AUT", "AZE", "BHS", "BHR",
        "BGD", "BRB", "BLR", "BEL", "BLZ", "BEN", "BTN", "BOL", "BIH", "BWA", "BRA", "BRN",
        "BGR", "BFA", "BDI", "CPV", "KHM", "CMR", "CAN", "CAF", "TCD", "CHL", "CHN", "COL",
        "COM", "COG", "COD", "CRI", "HRV", "CUB", "CYP", "CZE", "DNK", "DJI", "DMA", "DOM",
        "ECU", "EGY", "SLV", "GNQ", "ERI", "EST", "SWZ", "ETH", "FJI", "FIN", "FRA", "GAB",
        "GMB", "GEO", "DEU", "GHA", "GRC", "GRD", "GTM", "GIN", "GNB", "GUY", "HTI", "HND",
        "HUN", "ISL", "IND", "IDN", "IRN", "IRQ", "IRL", "ISR", "ITA", "JAM", "JPN", "JOR",
        "KAZ", "KEN", "KIR", "PRK", "KOR", "KWT", "KGZ", "LAO", "LVA", "LBN", "LSO", "LBR",
        "LBY", "LIE", "LTU", "LUX", "MDG", "MWI", "MYS", "MDV", "MLI", "MLT", "MHL", "MRT",
        "MUS", "MEX", "FSM", "MDA", "MCO", "MNG", "MNE", "MAR", "MOZ", "MMR", "NAM", "NRU",
        "NPL", "NLD", "NZL", "NIC", "NER", "NGA", "NOR", "OMN", "PAK", "PLW", "PAN", "PNG",
        "PRY", "PER", "PHL", "POL", "PRT", "QAT", "ROU", "RUS", "RWA", "KNA", "LCA", "VCT",
        "WSM", "SMR", "STP", "SAU", "SEN", "SRB", "SYC", "SLE", "SGP", "SVK", "SVN", "SLB",
        "SOM", "ZAF", "SSD", "ESP", "LKA", "SDN", "SUR", "SWE", "CHE", "SYR", "TJK", "TZA",
        "THA", "TLS", "TGO", "TON", "TTO", "TUN", "TUR", "TKM", "TUV", "UGA", "UKR", "ARE",
        "GBR", "USA", "URY", "UZB", "VUT", "VEN", "VNM", "YEM", "ZMB", "ZWE"
    ];

    function getCountryName(iso3Code) {
        const country = countryData.countries[iso3Code];
        return country ? country.name : "Unknown country code";
    }

    const [searchTerm, setSearchTerm] = useState("");

    // Filter based on both ISO3 code and country name
    const filteredData = searchTerm
        ? FullCountryList.filter(iso3Code => {
            const countryName = getCountryName(iso3Code);
            return (
                iso3Code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                countryName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
        : CountryList;

    const handleNavigate = () => {
        navigate("/all_countries&regions");
        window.scrollTo(0, 0);
    }

    const HandleCountryNameClick = (flag) => {
        localStorage.setItem("SelectedCountry", flag);
        navigate("/country_plans");
        window.scrollTo(0, 0);
    }

    return (
        <div className='FamousCountries'>
            <h1>Get mobile data wherever you travel.</h1>
            <h2>Right away. Super simple. Best price.</h2>


            <h3>Popular Countries <br />
                <span className='one_liner'>Get Connected from $0.99</span>
            </h3>

            <div>
                <center>
                    <input
                        type="text"
                        placeholder="Search by country"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className='serach_bar'
                    />
                </center>
                <div className='AllCountries'>
                    {
                        filteredData.map((country, index) => {
                            return <div key={index} className='CountryNameCard' onClick={() => HandleCountryNameClick(country)}>
                                <Flag code={country} id='Flag' />
                                <h3>{getCountryName(country)}</h3>
                                <ArrowRightRounded style={{ color: '#292B2E', fontSize: '40px' }} />
                            </div>
                        })
                    }
                </div>

                <center>
                    <Button
                        onClick={handleNavigate}
                        style={{
                            fontSize: "15px",
                            color: "white",
                            backgroundColor: "#00a1e4",
                            borderRadius: "30px",
                            marginTop: '30px',
                            padding: '7px 15px',
                        }}
                    >
                        Show All Countries +
                    </Button>
                </center>
            </div>
        </div>
    );
}

export default FamousCountries;
