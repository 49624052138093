import React from 'react';
import Slider from "react-slick";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './AppSection.css';
import Phone_2 from '../../../Assets/Digiesim - How digiesim works-22.png';
import Phone_3 from '../../../Assets/Digiesim - How digiesim works-23.png';
import Phone_4 from '../../../Assets/Digiesim - How digiesim works-24.png';

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowBackIosIcon
            className={className}
            style={{ ...style, display: "block", left: "-5px", color: "black", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowForwardIosIcon
            className={className}
            style={{ ...style, display: "block", right: "-5px", color: "black", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const AppSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <div className='AppSection'>
            <h2 style={{ fontSize: '28px', fontWeight: '500' }}>How digiesim works</h2>
            <h2 style={{ fontSize: '22px', fontWeight: '400' }}>Super simple. Zero Headache.</h2>

            {/* Slider for mobile */}
            <div className='PhonesBox slider-mobile'>
                <Slider {...settings}>
                    <div className='Phone'>
                        <img src={Phone_2} alt="Purchase a plan" />
                    </div>
                    <div className='Phone'>
                        <img src={Phone_3} alt="Follow eSIM instructions" />
                    </div>
                    <div className='Phone'>
                        <img src={Phone_4} alt="Activate your eSIM" />
                    </div>
                </Slider>
            </div>

            {/* Static content for desktop */}
            <div className='PhonesBox static-desktop'>
                <div className='Phone'>
                    <img src={Phone_2} alt="Purchase a plan" />
                </div>
                <div className='Phone'>
                    <img src={Phone_3} alt="Follow eSIM instructions" />
                </div>
                <div className='Phone'>
                    <img src={Phone_4} alt="Activate your eSIM" />
                </div>
            </div>
        </div>
    );
}

export default AppSection;
