import React, { useState, useEffect } from 'react';
import Flag from 'react-world-flags';
import { useNavigate, useLocation } from 'react-router-dom';
import './RegionCountry.css';
import { ArrowRightRounded } from '@mui/icons-material';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import { Bars } from 'react-loader-spinner';
import AsiaImg from '../../../Assets/Asiaa region icons-16.png';
import Europe from '../../../Assets/Europe region icons-15.png';
import MiddleEast from '../../../Assets/MiddleEast region icons-17.png';
import America from '../../../Assets/LatinAmerica region icons-18.png';
import Balkans from '../../../Assets/Balkans-21.png';
import Oceania from '../../../Assets/oceania region icons-14.png';
import Caucasus from '../../../Assets/Caucasus-22.png'
import Caribbean from '../../../Assets/caribbean region icons-19.png'
import { toast } from 'react-toastify';

const countryImages = [
    { iso3: 'APC', img: AsiaImg },
    { iso3: 'EUR', img: Europe },
    { iso3: 'MEA', img: MiddleEast },
    { iso3: 'LAT', img: America },
    { iso3: 'BLK', img: Balkans },
    { iso3: 'OC', img: Oceania },
    { iso3: 'CAU', img: Caucasus },
    { iso3: 'CRB', img: Caribbean },
];

const RegionCountryList = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const customId = "forNotShowingMultipleToast"



    const navigate = useNavigate();
    const [regionList, setRegionList] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true); // Add loading state

    const RegionISO3Code = localStorage.getItem('regionCode');
    const RegionName = localStorage.getItem("RegionName")

    const location = useLocation()
    let activeUrl = (location.pathname === '/region_plans')



    useEffect(() => {
        const fetchRegionList = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "region_iso3": RegionISO3Code
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            try {
                const response = await fetch(`${REACT_APP_BASE_URL}/get_regions`, requestOptions);
                const result = await response.json();
                setRegionList(result.countries);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchRegionList();
    }, []);

    const getImageByIso3 = (iso3) => {
        const country = countryImages.find(country => country.iso3 === iso3);
        return country ? country.img : '';
    };


    const filteredData = regionList.filter((item) => {
        const name = String(item.name || '');
        return name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleCountryNameClick = (countryCode) => {
        localStorage.setItem("SelectedCountry", countryCode);
        navigate("/country_plans");
        window.scrollTo(0, 0);
    };
    const handleRegionPlan = (RegionISO3Code) => {
        toast.info("Regions plans are coming soon.", {
            toastId: customId,
            autoClose: 2000,
            position: 'top-right'
        })
    };

    const handleNavigateRegionPlan = (name) => {
        localStorage.setItem('RegionName', name);
        navigate("/region_plans");

    }


    return (
        <>

            <div className="CountryInRegion">
                <h1 >Country List - <span style={{ color: "indianred" }} >{RegionName}</span> Region </h1>


                <div className="MainSection">
                    <div className="ImageSection" >
                        <img src={getImageByIso3(RegionISO3Code)} alt="Map" className="map-image" />
                    </div>

                    <div className="InfoSection" >
                        {!activeUrl &&
                            <>
                                <h2>{RegionName} eSIM <span>Coverage</span> </h2>
                                <div className='RegionCard' onClick={() => handleNavigateRegionPlan(RegionName)} >
                                    <div className='Names' style={{
                                        width: '80%'
                                    }} >
                                        <h4>View Plans</h4>
                                    </div>

                                    <ArrowRightRounded
                                        onClick={() => handleRegionPlan(RegionISO3Code)}
                                        style={{ color: '#292B2E', fontSize: '50px' }}
                                    />
                                </div>
                            </>
                        }
                        <p>Our <strong>{RegionName}+ eSIM Plan</strong> includes 4G LTE coverage throughout the following {RegionName} islands:</p>


                        <div className="CountryList">
                            {
                                filteredData.length > 0 ?
                                    filteredData.map((country, index) => (
                                        <div key={index} className="CountryData"
                                            onClick={() => handleCountryNameClick(country.iso3)}>
                                            <Flag code={country.iso3} id="Flag" />
                                            <p id="Name">{country.name}</p>
                                        </div>
                                    ))
                                    :
                                    <div className="CountryData">
                                        <p id="Name"> -- No countries present --</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {!activeUrl &&
                <>

                    <div id='searchFaq'>
                        <FAQ />
                    </div>
                    <Footer />
                </>

            }


        </>
    );
};

export default RegionCountryList;
