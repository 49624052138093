import './App.css';
import React, { useEffect, useState } from 'react'
import Login from './Components/Login Page/Login';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import SelectedCountryPlan from './Components/Selected Country Plans/SelectedCountryPlan';
import FAQ from './Components/HomePage/FAQ/FAQ';
import { ToastContainer, } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import HomePage from './Components/HomePage/HomePage';
import Payment from './Components/HomePage/Payment/Payment.jsx';
import PaymentSuccess from './Components/Payment/PaymentSuccess.jsx';
import PaymentCancel from './Components/Payment/PaymentCancel.jsx';
import Business from './Components/Business/Business.jsx';
import Country_List from './Components/HomePage/Landing Page/Country_List.jsx';
import Account from './Components/Account/Account.jsx';
import RegionCountryList from './Components/HomePage/Landing Page/RegionCountryList.jsx';
import SelectedRegionPlans from './Components/Selected Regions Plan/SelectedRegionPlans.jsx'

function App() {
  return (
    <Router>
      <ToastContainer />
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const showNavbar = (location.pathname !== '/forgot_password' && location.pathname !== '/login');

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/country_plans" element={<SelectedCountryPlan />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/payment_success" element={<PaymentSuccess />} />
        <Route path="/payment_failed" element={<PaymentCancel />} />
        <Route path="/business_section" element={<Business />} />
        <Route path="/all_countries&regions" element={<Country_List />} />
        <Route path='/account_information' element={<Account />}></Route>
        <Route path='/region_country_list' element={<RegionCountryList />}></Route>
        <Route path='/region_plans' element={<SelectedRegionPlans />}></Route>
      </Routes>
      {/* {showNavbar && <FAQ />} */}
    </>
  );
}

export default App;